import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import { BrowserRouter } from 'react-router-dom';

import App from './App';

import './index.css';

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);
